import { useState } from 'react';
import { cn } from '~/utils/';
import axios, { AxiosResponse } from 'axios';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { useCustomData } from '~/components/helper/store';
import { BsPaperclip } from 'react-icons/bs';

interface TImagePreview {
  id: string;
  base64: string;
  isLoading: boolean;
  uploaded: boolean;
  file_name: string;
}

interface TImageUrls {
  id: string;
  url: string;
  url_timestamp: number;
  url_expires: number;
  file_name: string;
}

interface TFileUploadProps {
  onFileChange: (imagesPreview: TImagePreview[]) => void;
  onUpload: (imageUrls: TImageUrls[]) => void;
  className?: string;
}
export default function FileUpload({ onFileChange, onUpload, className = '' }: TFileUploadProps) {
  const imagesPreview: TImagePreview[] = [];
  const { imagesPreview: selectedImages } = useCustomData((state) => state);
  const fileLimit = 5;

  const clearFile = () => {
    const uploadImage = document.getElementById('upload-image') as HTMLInputElement | null;
    if (uploadImage) {
      uploadImage.value = '';
    }
  };

  const showError = (errorMessage = '') => {
    toast.dismiss();
    toast.error(errorMessage);
    clearFile();
  };
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = event.target.files;
    if (!files) return;
    if (selectedImages.length + files.length > fileLimit) {
      showError('You can only upload up to ' + fileLimit + ' images');
      return;
    }

    const MB = 20;
    for (let i = 0; i < files.length; i++) {
      // check if file is an image image/png, image/jpeg, image/gif, image/webp
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (!validImageTypes.includes(files[i].type)) {
        showError('File type not supported');
        return;
      }

      // check all if file is greater than 20MB
      if (files[i].size > MB * 1024 * 1024) {
        showError('File size exceeds ' + MB + 'MB');
        return;
      }
      const isUploaded = selectedImages.some(
        (image: TImagePreview) => image.file_name === files[i].name,
      );

      if (isUploaded) {
        showError('File already uploaded');
        return;
      }
    }

    const uploadFileOnS3 = (file: File): Promise<TImageUrls> => {
      const id = v4();
      const file_name = file.name;

      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          imagesPreview.push({
            id: id,
            base64: e.target.result as string, // for image preview
            isLoading: true,
            uploaded: false,
            file_name: file_name,
          });
          onFileChange(imagesPreview);
        } else {
        }
      };
      reader.onerror = (e) => {
        console.log('reader error', e);
      };
      reader.readAsDataURL(file);

      // return image url
      return new Promise(async (resolve, reject) => {
        const dataURLtoFile = (dataurl: string, filename: string) => {
          const arr = dataurl.split(',');
          const mime = arr[0].match(/:(.*?);/)?.[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new File([u8arr], filename, { type: mime });
        };
        const resizeImage = async (file: File): Promise<File> => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              if (e.target?.result) {
                const image = new Image();
                image.onload = () => {
                  const canvas = document.createElement('canvas');
                  const ctx = canvas.getContext('2d');
                  // Set the desired width and height for the resized image
                  const maxWidth = 1024;
                  const maxHeight = 1024;
                  let width = image.width;
                  let height = image.height;

                  // Calculate the new dimensions while maintaining the aspect ratio
                  if (width > height) {
                    if (width > maxWidth) {
                      height = Math.round((height *= maxWidth / width));
                      width = maxWidth;
                    }
                  } else {
                    if (height > maxHeight) {
                      width = Math.round((width *= maxHeight / height));
                      height = maxHeight;
                    }
                  }

                  canvas.width = width;
                  canvas.height = height;

                  if (ctx) {
                    ctx.drawImage(image, 0, 0, width, height);
                    const resizedImage = canvas.toDataURL(file.type, 0.5);
                    const resizedFile = dataURLtoFile(resizedImage, file.name);
                    resolve(resizedFile);
                  } else {
                    reject(new Error('Failed to create canvas'));
                  }
                };

                image.src = e.target.result as string;
              } else {
                reject(new Error('Failed to read image'));
              }
            };
            reader.onerror = (e) => {
              reject(e);
            };
            reader.readAsDataURL(file);
          });
        };

        const resizedImage = await resizeImage(file);

        const formData = new FormData();
        const aiwp_logged_in =
          document.cookie
            .split('; ')
            .find((row) => row.startsWith('aiwp_logged_in='))
            ?.split('=')[1] || '';
        formData.append('aiwp_logged_in', aiwp_logged_in);
        formData.append('file', resizedImage);
        formData.append('file_id', id);

        const endpoint =
          import.meta.env.VITE_UPLOAD_API_URL || 'http://localhost:8001/e/v1/upload-chatbotpro';
        axios
          .post(endpoint, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response: AxiosResponse) => {
            if (response.data?.status == 'success') {
              if (imagesPreview && imagesPreview.length > 0) {
                imagesPreview.forEach((image: TImagePreview) => {
                  if (image.id == id) {
                    image.isLoading = false;
                    image.uploaded = true;
                  }
                });
                onFileChange(imagesPreview);
              }

              resolve({
                id: id,
                url: response.data.url as string,
                url_timestamp: Date.now(),
                url_expires: Date.now() + 10080 * 60 * 1000, // 7 days
                // url_expires: Date.now() + 1 * 60 * 1000, //
                file_name: response.data.file_name,
              });
            } else {
              reject(new Error('File reading error'));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    try {
      const imageUrls = await Promise.all(Array.from(files).map(uploadFileOnS3));
      onUpload(imageUrls);
      // remove all uploaded images
    } catch (error) {
      console.error('Error uploading files:', error);
      onFileChange([]);
      onUpload([]);
    }
  };

  return (
    <>
      <div className='flex items-center justify-center'>
        <label
          htmlFor="upload-image"
          className="relative p-2 rounded-full border-[#E4E4E7] border bg-[#FFFFFF] hover:bg-[#F5F5F5]" 
          role="button"
          aria-label="Upload Image"
          title="Upload Image"
          onClick={() => {
            if (selectedImages.length >= fileLimit) {
              toast.dismiss();
              toast.error('You can only upload up to ' + fileLimit + ' files');
            }
          }}
        >
          <BsPaperclip size={25} className='text-[#1A1D21BF]' />
          <input
            id="upload-image"
            type="file"
            className={cn('hidden ', className)}
            accept="image/png, image/jpeg, image/gif, image/webp"
            onChange={handleFileChange}
            multiple
            disabled={selectedImages.length >= fileLimit}
          />
        </label>
      </div>
    </>
  );
}
