"use client";

import { useEffect } from 'react';

const get_flag = (paramName: string) => {
  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [key, value] = cookie.split('=');
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {} as Record<string, string>);
  return cookies[paramName] || null;
};

const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
};

// Define a type for your global variables
interface GlobalVariables {
  mxname: string;
  mxuserEmail: string;
  mxuserPlan: string;
  mxcurrency: string;
  mxdebugMode: string;
  mxamount: string;
  mxkeyword: string;
  mxrewardfulVia: string;
  mxemailid: string;
  mxadid: string;
  mxppg: string;
  mxpmt: string;
  mxhowdoiplantouse: string;
  mxremakemedloption: string;
  mxtheme: string;
  mxlocales: string;
  gaw_currency: string;
  mxclickLocation: string;
  mxupgrade: string;
  mxPanelTrack: string;
  mxPanelClickLocation: string;
}

// Declare global variables on the window object
declare global {
  interface Window extends GlobalVariables {}
}

const initializeGlobalVariables = () => {
  window.mxname = window.mxname || "";
  window.mxuserEmail = window.mxuserEmail || "";
  window.mxuserPlan = window.mxuserPlan || "";
  window.mxcurrency = window.mxcurrency || "";
  window.mxdebugMode = window.mxdebugMode || "";
  window.mxamount = window.mxamount || "";
  window.mxkeyword = window.mxkeyword || "";
  window.mxrewardfulVia = window.mxrewardfulVia || "";
  window.mxemailid = window.mxemailid || "";
  window.mxadid = window.mxadid || "";
  window.mxppg = window.mxppg || "";
  window.mxpmt = window.mxpmt || "";
  window.mxhowdoiplantouse = window.mxhowdoiplantouse || "";
  window.mxremakemedloption = window.mxremakemedloption || "";
  window.mxtheme = window.mxtheme || "";
  window.mxlocales = window.mxlocales || "";
  window.gaw_currency = window.gaw_currency || "";
  window.mxclickLocation = window.mxclickLocation || "";
  window.mxupgrade = window.mxupgrade || "";
  window.mxPanelTrack = window.mxPanelTrack || "";
  window.mxPanelClickLocation = window.mxPanelClickLocation || "";
};

export default function ClientComponent() {
  useEffect(() => {
    initializeGlobalVariables();
    const openai_default_model = window.mxPanelTrack || import.meta.env.VITE_FORCE_MODEL;
    const baseDomain = window.location.hostname;
    const urlParams = new URLSearchParams(window.location.search);
    const paramsToSet = ['name', 'user_email', 'user_plan', 'currency', 'via', 'price', 'keyword', 'emailid', 'adid', 'ppg', 'pmt'];

    paramsToSet.forEach(param => {
      const paramValue = urlParams.get(param);
      if (paramValue) {
        setCookie(param, paramValue, 30);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.mxname = baseDomain ?? "chatpro.chatbotask.ai";
    window.mxdebugMode = "false";
    window.mxuserEmail = get_flag('user_email') ?? "";
    window.mxuserPlan = get_flag('user_plan') ?? "";
    window.mxrewardfulVia = get_flag('via') ?? "";
    window.mxcurrency = get_flag('currency') ?? "USD";
    window.mxamount = get_flag('price') ?? "0";
    window.mxkeyword = get_flag('keyword') ?? "";
    window.mxemailid = get_flag('emailid') ?? "";
    window.mxadid = get_flag('adid') ?? "";
    window.mxppg = get_flag('ppg') ?? "";
    window.mxpmt = get_flag('pmt') ?? "";
    window.mxhowdoiplantouse = get_flag('howdoiplantouse') ?? "";
    window.mxremakemedloption = get_flag('remakemedloption') ?? "";
    window.mxtheme = get_flag('kt8typtb') ?? "arcana";
    window.mxlocales = get_flag('locales') ?? "en";
    window.gaw_currency = get_flag('currency') ?? "USD";
    window.mxPanelTrack = openai_default_model ?? "";


    if (typeof mixpanel != 'undefined') {
      mixpanel.track(window.mxname, {
        'keyword': window.mxkeyword,
        'emailid': window.mxemailid,
        'adid': window.mxadid,
        'ppg': window.mxppg,
        'pmt': window.mxpmt,
        '$email': window.mxuserEmail,
        'remakemedloption': window.mxremakemedloption,
      });
    }

  }, []);

  return null;
}
