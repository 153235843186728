//ThemeContext.js
// source: https://plainenglish.io/blog/light-and-dark-mode-in-react-web-application-with-tailwind-css-89674496b942

import { createContext, useState, useEffect } from 'react';

const THEME_CLASSES = [
  'aubergine',
  'ochin',
  'monument',
  'work-hard',
  'aubergine-classic',
  'eggplant',
  'hoth',
  'mondrian',
  'ultraviolet',
  'mood-indigo',
  'cmyk',
  'nocturne',
  'haberdashery',
  'expensive',
  'terminal',
  'sweet-treat',
  'banana',
  'choco-mint',
  'discotheque',
  'tritanopia',
  'default',
  'default-two',
];

const getInitialTheme = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    // const localTheme = window.localStorage.getItem('color-theme');
    // for users with gray local storage value theme will be default
    // return localTheme && THEME_CLASSES.includes(localTheme)
    //   ? localTheme === 'default' || localTheme === 'gray'
    //     ? 'default-two'
    //     : localTheme
    //   : 'default-two';
    return 'default-two';
  }
};

const getInitialMode = () => {
  if (typeof window !== 'undefined') {
    const storedMode = window.localStorage?.getItem('MODE') ?? '';
    if (storedMode) {
      return storedMode;
    }

    // const userMedia = window.matchMedia('(prefers-color-scheme: dark)');
    // if (userMedia.matches) {
    //   return 'dark';
    // }
  }

  return 'light'; // light theme as the default;
};

export const addThemeListener = (mediaQuery: string, handler: (e) => void): (() => void) => {
  const userMedia = window.matchMedia(mediaQuery);

  if (userMedia) {
    if (typeof userMedia.addEventListener === 'function') {
      userMedia.addEventListener('change', (e) => handler(e));
    } else if (typeof userMedia.addListener === 'function') {
      // For older browsers
      userMedia.addListener((e) => handler(e));
    }

    return () => {
      if (typeof userMedia.removeEventListener === 'function') {
        userMedia.removeEventListener('change', (e) => handler(e));
      } else if (typeof userMedia.removeListener === 'function') {
        // For older browsers
        userMedia.removeListener((e) => handler(e));
      }
    };
  }

  // Return a dummy function if userMedia is falsy
  return () => {
    /* No code intentionally */
  };
};

export const ThemeContext = createContext();

export const ThemeProvider = ({ initialTheme, initialMode, children }) => {
  const [theme, setTheme] = useState(getInitialTheme());
  const [mode, setMode] = useState(getInitialMode());

  const rawSetTheme = (rawTheme) => {
    const root = window.document.documentElement;

    THEME_CLASSES.forEach((item) => {
      root.classList.remove(item);
    });
    if (rawTheme) {
      root.classList.add(rawTheme);
      localStorage.setItem('color-theme', rawTheme);
    }
  };
  const rawSetMode = (rawMode) => {
    const root = window.document.documentElement;

    root.classList.remove('light');
    root.classList.remove('dark');
    if (rawMode) {
      if (rawMode == 'sync-to-system') {
        const isDark = window.matchMedia('(prefers-color-scheme:dark)').matches;
        root.classList.add(isDark ? 'dark' : 'light');
      } else {
        root.classList.add(rawMode);
      }
      localStorage.setItem('MODE', rawMode);
    }
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  if (initialMode) {
    rawSetMode(initialMode);
  }
  useEffect(() => {
    rawSetMode(mode);
  }, [mode]);

  // sync to system
  useEffect(() => {
    const handleChange = (e) => {
      const storedMode = window.localStorage?.getItem('MODE') ?? '';

      if (storedMode === 'sync-to-system') {
        const isDark = e.matches;
        const root = window.document.documentElement;

        if (root) {
          root.classList.remove('light', 'dark');
          root.classList.add(isDark ? 'dark' : 'light');
        }
      }
    };

    addThemeListener('(prefers-color-scheme:dark)', handleChange);
  }, []); // end useEffect for sync to system

  return (
    <ThemeContext.Provider value={{ theme, setTheme, mode, setMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
