export const getPrefixLocation = () => {
    const currentLocation = window.location.href;

    if (currentLocation.indexOf("staging") > -1) {
        return "staging.";
    } else if (currentLocation.indexOf("dev") > -1) {
        return "dev.";
    }

    return ""
}

export const setFlags = () => {
    const setCookie = (key, value) => {
      document.cookie = key + '=' + encodeURIComponent(value) + '; domain=.ai-pro.org; path=/';
    };
    
    const setFlagFromURL = (flagName) => {
      const flagValue = new URLSearchParams(window.location.search).get(flagName) ?? "on";
      if (flagValue !== null && flagValue !== '') {
        setCookie(flagName, flagValue);
      }
    };
    
    setFlagFromURL('reg_apple');
    setFlagFromURL('reg_google');
}

export const handleRedirect = (path) => {
    window.location.href = path;
}