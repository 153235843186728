import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import NewChat from './NewChat';
import Spinner from '../svg/Spinner';
import Pages from '../Conversations/Pages';
import { Conversations } from '../Conversations';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useGetConversationsQuery, useSearchQuery } from '~/data-provider';
import useDebounce from '~/hooks/useDebounce';
import store from '~/store';
import { useAuthContext } from '~/hooks/AuthContext';
import { ThemeContext } from '~/hooks/ThemeContext';
import { cn } from '~/utils/';
import { useMediaQuery } from '~/hooks';
import { LuPanelLeft } from 'react-icons/lu';
import AdvanceTools from './AdvanceTools';
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import global from '~/utils/global';
import { usePricingPopup } from '../helper/store';

export default function Nav({ navVisible, setNavVisible }) {
  const [isHovering, setIsHovering] = useState(false);
  const { isAuthenticated } = useAuthContext();
  const Theme = useContext(ThemeContext);
  const containerRef = useRef(null);
  const scrollPositionRef = useRef(null);

  const [conversations, setConversations] = useState([]);
  // current page
  const [pageNumber, setPageNumber] = useState(1);
  // total pages
  const [pages, setPages] = useState(1);

  // data provider
  const getConversationsQuery = useGetConversationsQuery(pageNumber, { enabled: isAuthenticated });

  // search
  const searchQuery = useRecoilValue(store.searchQuery);
  const isSearchEnabled = useRecoilValue(store.isSearchEnabled);
  const isSearching = useRecoilValue(store.isSearching);
  const { newConversation, searchPlaceholderConversation } = store.useConversation();

  // current conversation
  const conversation = useRecoilValue(store.conversation);
  const { conversationId } = conversation || {};
  const setSearchResultMessages = useSetRecoilState(store.searchResultMessages);
  const refreshConversationsHint = useRecoilValue(store.refreshConversationsHint);
  const { refreshConversations } = store.useConversations();

  const [isFetching, setIsFetching] = useState(false);

  const debouncedSearchTerm = useDebounce(searchQuery, 750);
  const searchQueryFn = useSearchQuery(debouncedSearchTerm, pageNumber, {
    enabled:
      !!debouncedSearchTerm && debouncedSearchTerm.length > 0 && isSearchEnabled && isSearching,
  });

  const [showVuzo, setShowVuzo] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const [srVisible, setSrVisible] = useState(true);
  const pState = usePricingPopup((state) => state);

  useEffect(() => {
    const forUserInterval = setInterval(() => {
      if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
        const subscriptionType = AIPRO_USER.subscription_type;

        if (subscriptionType) {
          setShowVuzo(true);
        }
        clearInterval(forUserInterval);
      }
    }, 300);
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      setNavVisible(false);
    } else {
      const savedNavVisible = localStorage.getItem('navVisible');
      setNavVisible(savedNavVisible !== null ? JSON.parse(savedNavVisible) : true);
    }
  }, [isSmallScreen]);

  const onSearchSuccess = (data, expectedPage) => {
    const res = data;
    setConversations(res.conversations);
    if (expectedPage) {
      setPageNumber(expectedPage);
    }
    setPages(res.pages);
    setIsFetching(false);
    searchPlaceholderConversation();
    setSearchResultMessages(res.messages);
  };

  useEffect(() => {
    //we use isInitialLoading here instead of isLoading because query is disabled by default
    if (searchQueryFn.isInitialLoading) {
      setIsFetching(true);
    } else if (searchQueryFn.data) {
      onSearchSuccess(searchQueryFn.data);
    }
  }, [searchQueryFn.data, searchQueryFn.isInitialLoading]);

  const clearSearch = () => {
    setPageNumber(1);
    refreshConversations();
    if (conversationId == 'search') {
      newConversation();
    }
  };

  const moveToTop = () => {
    const container = containerRef.current;
    if (container) {
      scrollPositionRef.current = container.scrollTop;
    }
  };

  const nextPage = async () => {
    moveToTop();
    setPageNumber(pageNumber + 1);
  };

  const previousPage = async () => {
    moveToTop();
    setPageNumber(pageNumber - 1);
  };

  useEffect(() => {
    if (getConversationsQuery.data) {
      if (isSearching) {
        return;
      }
      let { conversations, pages } = getConversationsQuery.data;
      if (pageNumber > pages) {
        setPageNumber(pages);
      } else {
        if (!isSearching) {
          conversations = conversations.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          );
        }
        setConversations(conversations);
        setPages(pages);
      }
    }
  }, [getConversationsQuery.isSuccess, getConversationsQuery.data, isSearching, pageNumber]);

  useEffect(() => {
    if (!isSearching) {
      getConversationsQuery.refetch();
    }
  }, [pageNumber, conversationId, refreshConversationsHint]);

  const toggleNavVisible = useCallback(() => {
    setNavVisible((prev: boolean) => !prev);

    if (!isSmallScreen) {
      localStorage.setItem('navVisible', JSON.stringify(!navVisible));
    }
  }, [isSmallScreen, navVisible]);

    const handleClickModal = () => {
      global.showPricing(() => {
        pState.setShowRegisterPopup(true);
      });
    };

  return (
    <>
      <div
        className={cn(
          'min-h-nav absolute h-full w-auto md:relative md:inset-y-0',
          navVisible ? 'z-40' : '-z-10 md:z-40',
        )}
      >
        <div
          className={cn(
            'nav flex h-full w-[calc(100vw-60px)] max-w-[280px] flex-col overflow-hidden transition-[width] duration-200 ease-in-out',
            navVisible ? '' : 'w-0',
          )}
        >
          <div className="scrollbar-trigger relative flex h-full w-[calc(100vw-60px)] max-w-[280px] flex-1 items-start border-white/20">
            <nav className="relative flex h-full flex-1 flex-col px-4 py-6 pt-0 pointer-events-auto">
              <div className="flex items-center py-4 md:hidden">
                <img src="/assets/ai-pro-logo-new.png" alt="Chatbot Ask" className="w-10 h-10 mb-2" />
                <span className="ml-2 text-[26px] font-bold text-gray-900 dark:text-white">
                  Chatbot Pro
                </span>
              </div>
              <NewChat
                onAfterClick={() => setNavVisible(false)}
                setNavVisible={setNavVisible}
              />
              <div className='inline-flex mt-4'>
                <MdOutlineMarkEmailUnread />
                <h3 className='conversations-title font-bold pl-2 text-xs mb-2'>Chat History</h3>
              </div>
              <div
                className={cn(
                  'scrollbar-transparent  max-w-[248px] flex-1 flex-col overflow-y-auto overflow-x-hidden',
                  {
                    'scrollbar-hide': !isHovering,
                    'pr-2': isHovering && conversations.length !== 0,
                    'place-content-center': !conversations.length,
                  },
                )}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                ref={containerRef}
              >
                {(getConversationsQuery.isLoading || isFetching) ? (
                  <div
                    className="w-full flex items-center justify-center text-center my-auto text-sm"
                    style={{ height: "-webkit-fill-available" }}
                  >
                    <Spinner />
                  </div>
                ) : conversations.length === 0 ? (
                  <div>
                  <img src="/assets/NoChat.svg" alt="NoChat" className="mb-2" />
                      <div
                        className="w-full flex flex-col items-center justify-center text-center my-auto text-gray-400 text-sm"
                      >
                        No chat history.
                        <span>
                          <button onClick={handleClickModal} className='underline text-blue-500'>Create one</button> to get started.
                        </span>
                      </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-1 text-sm">
                    <Conversations
                      conversations={conversations}
                      moveToTop={moveToTop}
                      setNavVisible={setNavVisible}
                    />
                    <Pages
                      pageNumber={pageNumber}
                      pages={pages}
                      nextPage={nextPage}
                      previousPage={previousPage}
                    />
                  </div>
                )}
              </div>
              <AdvanceTools />
            </nav>
          </div>
        </div>
        {(!isSmallScreen || (isSmallScreen && navVisible)) && (
          <button
            type="button"
            className={cn(
              'group absolute left-[83%] top-[1.7rem] ml-2 z-50 block h-4 w-4 items-center justify-center rounded-md',
              Theme?.mode === 'dark'
                ? 'text-gray-500 hover:text-gray-400'
                : 'text-gray-400 hover:text-gray-500',
            )}
            onClick={() => {
              toggleNavVisible();
              setSrVisible(false);
            }}
            onMouseEnter={() => setSrVisible(true)}
            onMouseLeave={() => setSrVisible(false)}
          >
            {srVisible && (
              <span className="pointer-events-none absolute left-7 top-1/2 z-50 hidden h-max w-max -translate-y-1/2 overflow-visible whitespace-nowrap rounded bg-black px-2 py-1.5 text-sm text-white opacity-0 transition-opacity duration-500 ease-in-out [clip:_unset] group-hover:opacity-100 dark:bg-black dark:text-white/90 sm:block">
                <span className="absolute -left-1 top-[65%] -z-10 block h-2.5 w-2.5 -translate-y-1/2 rotate-45 bg-inherit" />
                {navVisible ? 'Close' : 'Open'} sidebar
              </span>
            )}
            <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="1" width="26" height="26" rx="4.5" stroke="black" stroke-opacity="0.1" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 7.5C7.11929 7.5 6 8.61929 6 10V18C6 19.3807 7.11929 20.5 8.5 20.5H12V7.5H8.5ZM13 7.5V20.5H18.5C19.8807 20.5 21 19.3807 21 18V10C21 8.61929 19.8807 7.5 18.5 7.5H13ZM5 10C5 8.067 6.567 6.5 8.5 6.5H18.5C20.433 6.5 22 8.067 22 10V18C22 19.933 20.433 21.5 18.5 21.5H8.5C6.567 21.5 5 19.933 5 18V10ZM8 11C8 10.7239 8.22386 10.5 8.5 10.5H9.5C9.77614 10.5 10 10.7239 10 11C10 11.2761 9.77614 11.5 9.5 11.5H8.5C8.22386 11.5 8 11.2761 8 11ZM8 14C8 13.7239 8.22386 13.5 8.5 13.5H9.5C9.77614 13.5 10 13.7239 10 14C10 14.2761 9.77614 14.5 9.5 14.5H8.5C8.22386 14.5 8 14.2761 8 14Z" fill="black" />
            </svg>
          </button>
        )}
      </div>
      {isSmallScreen && (
        <div
          className={cn(
            'absolute bottom-0 left-0 right-0 top-0 z-[32] bg-[rgba(86,_88,_105,_0.75)] transition-opacity duration-200 ease-linear',
            navVisible ? ' pointer-events-auto opacity-100' : 'pointer-events-none opacity-0',
          )}
          onClick={toggleNavVisible}
        />
      )}
    </>
  );
}
