import React, { useState, useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import store from '~/store';
import { useLocalize } from '~/hooks';
import { HiSparkles } from 'react-icons/hi2';
import HeaderDropdown from '~/components/Header/HeaderDropdown';
import { usePricingPopup, subscriptionType } from '~/components/helper/store';
import { showPricing } from '~/components/helper/global';
import { FaUser } from 'react-icons/fa6';
import Settings from '../Header/HeaderSettings';
import { useChatContext } from '~/hooks/ChatContext';
import { IoDiamond } from "react-icons/io5";
import ModelDropdown from '~/components/Header/AiModelDropdown';
import { FaRegEdit } from "react-icons/fa";
import global from '~/utils/global';

export default function MobileNav({ setNavVisible, showThemeModal }) {
  const conversation = useRecoilValue(store.conversation);
  const { newConversation } = store.useConversation();
  const { title = 'New Chat' } = conversation || {};
  const localize = useLocalize();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const dropdownRef = useRef(null);
  const pState = usePricingPopup((state) => state);
  const proState = subscriptionType((state) => state)
  const { resetPoweredBy } = useChatContext();
  const messagesTree = useRecoilValue(store.messagesTree);
  const [isMobile, setIsMobile] = useState(false);
  const { setShowRegisterPopup } = usePricingPopup();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const toggleDropdown = () => {
    if (isDropdownOpen) {
      setIsDropdownVisible(false);
      setTimeout(() => setIsDropdownOpen(false), 200);
    } else {
      setIsDropdownOpen(true);
      setTimeout(() => setIsDropdownVisible(true), 10);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
      setTimeout(() => setIsDropdownOpen(false), 200);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const clickHandler = () => {
    newConversation();
    if (messagesTree && messagesTree.length > 0) {
      resetPoweredBy();
    }
  };

  const handleClickModal = () => {
    global.showPricing(() => {
      setShowRegisterPopup(true);
    });
  };

  return (

    <div className="mobile-nav bg-white nav-menu absolute left-0 right-0 top-0 z-20 flex items-center dark:bg-[#1A1D22] pl-1 py-2 px-2 text-gray-200 sm:pl-3 md:hidden">
      <button
        type="button"
        className="menu-btn ml-2 text-gray-500 dark:text-white -mt-0.5 inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900  dark:hover:text-white"
        onClick={() => setNavVisible((prev) => !prev)}
      >
        <span className="sr-only">{localize('com_nav_open_sidebar')}</span>
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="h-12 w-12 menu-icon"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="3" y1="12" x2="19" y2="12" />
          <line x1="3" y1="6" x2="19" y2="6" />
          <line x1="3" y1="18" x2="19" y2="18" />
        </svg>
      </button>
      {/* <h1 className="nav-title flex-1 text-center text-base font-normal">
        {title || localize('com_ui_new_chat')}
      </h1> */}
      <div className="flex justify-center w-full items-center  relative gap-0">
        <div className='w-full md:ml-auto'></div>
        <ModelDropdown className="" />
        <div className='flex md:ml-auto'>
        </div>
        <div className='w-full md:ml-auto'></div>
        <div className='inline-flex w-full justify-end'>
          <button
            type="button"
            className="new-chat-mobile-btn mx-2"
            aria-label="new chat"
            onClick={() => clickHandler()}
          >
            <div className="block dark:hidden">
              <FaRegEdit className='inline text-[28px] text-gray-500' />
            </div>
          </button>
          <button
            className="animate-peakFromBottom text-md md:text-xl inline-block cursor-pointer rounded-lg bg-blue-500 p-2 text-white duration-200 ease-in hover:bg-blue-600 mr-2"
            onClick={() => {
              if (window.mixpanel) {
                window.mixpanel.track('Chatpro-App', {
                  'click-location': 'Go-Pro-Button',
                });
              }
              handleClickModal();
            }}
            title="GO PRO"
            type="button"
          >
            <div className="tabs flex-1 flex flex-col items-center">
              <IoDiamond className="inline text-white" /> {!isMobile ? ' PRO' : ''}
            </div>
          </button>
        </div>

        {/* <button
          className="bg-[#015288] text-white text-xl font-bold rounded-full px-2 py-2 flex items-center justify-center mx-auto"
          style={{ width: '40px', height: '40px' }}
          onClick={() => toggleDropdown()}
        >
          {AIPRO_USER.email ? <FaUser size={20} role="img" aria-label="User" /> : 'G'}
        </button> */}
      </div>
      {isDropdownOpen && (
        <div ref={dropdownRef}>
          <HeaderDropdown
            isVisible={isDropdownVisible}
            setIsDropdownVisible={setIsDropdownVisible}
            setShowSettings={setShowSettings}
            showThemeModal={showThemeModal}
          ></HeaderDropdown>
        </div>
      )}
      {showSettings && <Settings open={showSettings} onOpenChange={setShowSettings} />}
    </div>
  );
}
