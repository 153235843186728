import { useState, useEffect, useRef } from "react";
import { Check, ChevronDown } from "lucide-react";
import { cn } from "~/utils"
import { useRecoilValue, useRecoilState } from 'recoil';
import store from '~/store';
import { aiModelState } from "~/store/aiModels";
import { showPricing } from "../helper/global";
import { usePricingPopup } from "../helper/store";
import OpenAIIcon from "~/components/icons/OpenAIIcon";
import ClaudeIcon from "~/components/icons/ClaudeIcon";
import DeepSeekIcon from "~/components/icons/DeepSeekIcon";
import GeminiIcon from "~/components/icons/GeminiIcon";
import { HiSparkles } from 'react-icons/hi2';
import global from "~/utils/global";

type ModelOption = {
  id: string;
  name: string;
  description: string;
  icon: JSX.Element;
  isPro?: boolean;
};

export default function ModelDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useRecoilState(aiModelState);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const messagesTree = useRecoilValue(store.messagesTree);
  const { newConversation } = store.useConversation();
  const pState = usePricingPopup((state) => state);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const models: ModelOption[] = [
    {
      id: "gpt-4o-mini",
      name: "GPT-4o mini",
      description: "OpenAI's Fastest Model",
      icon: <IconWrapper><OpenAIIcon /></IconWrapper>,
    },
    {
      id: "gpt-4o",
      name: "GPT-4o",
      description: "OpenAI's Most Accurate Model",
      isPro: true,
      icon: <IconWrapper><OpenAIIcon /></IconWrapper>,
    },
    {
      id: "claude",
      name: "Claude",
      description: "Anthropic's latest AI model",
      isPro: true,
      icon: <IconWrapper><ClaudeIcon /></IconWrapper>,
    },
    {
      id: "deepseek",
      name: "DeepSeek",
      description: "Great for most questions",
      isPro: true,
      icon: <IconWrapper><DeepSeekIcon /></IconWrapper>,
    },
    {
      id: "gemini",
      name: "Google Gemini",
      description: "Google's most capable AI",
      isPro: true,
      icon: <IconWrapper><GeminiIcon /></IconWrapper>,
    },
  ];

  function IconWrapper({ children }: { children: React.ReactNode }) {
    return (
      <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white shadow">
        {children}
      </div>
    );
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectModel = (modelId: string, isPro?: boolean) => {
    if (modelId !== "gpt-4o-mini" && isPro) {
      showPricing(() => {
        pState.setShowRegisterPopup(true);
      });
    }
    setIsOpen(false);
  };

  useEffect(() => {
    console.log("Selected Model:", selectedModel);
  }, [selectedModel]);


  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const selectedModelData = models.find((model) => model.id === selectedModel);

  const handleModelClick = () => {
    global.showPricing(() => {
      pState.setShowRegisterPopup(true);
    });
  };

  return (
    <>
      {messagesTree?.length ? (
        <div
          className={`w-full max-w-xs ${messagesTree?.length ? 'block md:absolute right-[0px] pr-[10px]' : ''}`}
          ref={dropdownRef}
        >
          <div className="md:flex items-center hidden">
            <button
              onClick={handleModelClick}
              className="px-5 pr-6 py-2 bg-gradient-to-r from-[#3E53B8] to-[#2393F1] hover:bg-blue-700 text-white rounded-full w-max text-sm font-semibold transition-colors duration-200 self-center"
            >
              <span className="inline-flex items-center">
                <HiSparkles className="mr-2" />Go Pro
              </span>
            </button>
          </div>
        </div>
      ) : null}

      <div
        className={`w-full max-w-xs model-dropdown md:pr-0 ${messagesTree?.length ? 'block md:absolute md:right-[-130px]' : 'ml-14 md:ml-0'}`}
        ref={dropdownRef}
      >
        <div className="relative !text-right">
          <button
            type="button"
            onClick={toggleDropdown}
            className={cn(
              "items-right justify-end rounded-md hover:bg-[#F5F5F5] p-[4px] text-right text-sm font-medium text-gray-900 w-fit z-50",
              messagesTree?.length
                ? `block md:absolute top-[-20px] border-[#E4E4E7] border rounded-full`
                : "p-2.5 rounded-full border-[#E4E4E7] border bg-[#FFFFFF]",
              isOpen && "bg-[#F5F5F5]"
            )}>
            <div className="flex items-center">
              {messagesTree?.length ? (
                <div className="flex h-8 w-8 items-center justify-center">
                  {selectedModelData?.icon}
                </div>
              ) : null}
              <span className={`${!messagesTree?.length ? 'font-medium' : ''} whitespace-nowrap pr-1`}>{selectedModelData?.name}</span>
              <ChevronDown className="px-[2px] h-5 w-5 text-gray-500" />
            </div>
          </button>
          {isOpen && (
            <div
            className={`absolute z-50 mt-1 w-full origin-top-right rounded-lg shadow-xl bg-white border border-gray-50 ${messagesTree?.length ? 'top-12 right-[-80px] md:top-8 md:right-[170px]' : 'top-12 right-[-85px]'} w-max bg-white border rounded-3xl`}
>
              <div className="py-1">
                <div className="max-h-[370px] overflow-y-auto">
                  {models.map((model) => (
                    <div className="px-2 py-[2px] mt-1" key={model.id}>
                      <button
                        onClick={() => selectModel(model.id, model.isPro)}
                        {...(isMobile && { onTouchStart: () => selectModel(model.id, model.isPro) })}
                        className="flex w-full items-center gap-4 justify-between px-4 py-2 text-left text-sm hover:bg-gray-50 rounded-lg"
                      >
                        <div className="flex items-center gap-3">
                          <div className="flex h-8 w-8 items-center justify-center">{model.icon}</div>
                          <div>
                            <div className="font-bold text-gray-900">{model.name}</div>
                            <div className="text-[11px] text-gray-600 font-normal">{model.description}</div>
                          </div>
                        </div>
                        <div className="flex items-center gap-3">
                          {selectedModel !== model.id && model.isPro && (
                            <span className="rounded bg-[#E0F3FF] px-2 py-0.5 text-xs font-medium text-[#2872FA]">
                              Pro
                            </span>
                          )}
                          {selectedModel === model.id && (
                            <Check className="h-4 w-4 text-[#2872FA] mx-2" />
                          )}
                        </div>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
