import { createRoot } from 'react-dom/client';
import App from './App';
import './style.css';
import './mobile.css';
import { ApiErrorBoundaryProvider } from './hooks/ApiErrorBoundaryContext';
import { ChatProvider } from './hooks/ChatContext';
import { useState, useEffect } from 'react';

// Import theme styles
import './css-themes/aubergine.css';
import './css-themes/default-two.css';
import './css-themes/ochin.css';
import './css-themes/monument.css';
import './css-themes/work-hard.css';
import './css-themes/aubergine-classic.css';
import './css-themes/eggplant.css';
import './css-themes/hoth.css';
import './css-themes/mondrian.css';
import './css-themes/ultraviolet.css';
import './css-themes/mood-indigo.css';
import './css-themes/cmyk.css';
import './css-themes/nocturne.css';
import './css-themes/haberdashery.css';
import './css-themes/expensive.css';
import './css-themes/terminal.css';
import './css-themes/sweet-treat.css';
import './css-themes/banana.css';
import './css-themes/choco-mint.css';
import './css-themes/discotheque.css';
import './css-themes/tritanopia.css';

const bounceKeyframes = `
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
`;

const LoadingFallback = () => (
  <>
    <style>{bounceKeyframes}</style>
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1.5rem',
          fontSize: '1.25rem',
          color: '#292929',
        }}
      >
        <img
          src="/assets/ai-pro-logo-new.png"
          alt="Ai logo"
          style={{
            height: 'auto',
            width: '150px',
            animation: 'bounce 1s infinite',
          }}
          width="841"
          height="512"
          className=""
        />
        <p className='text-[#292929]'>Activating AI chat...</p>
      </div>
    </div>
  </>
);

const redirectUrl = import.meta.env.VITE_REDIRECT_URL;

const LimitedUse = () => {
  const [loading, setLoading] = useState(true);
  const user = typeof window.AIPRO_USER !== 'undefined' && window.AIPRO_USER;

  useEffect(() => {
    const getUserSubscriptionType = setInterval(() => {
      if (user) {
        const userSubscriptionType = user.subscription_type;
        if (userSubscriptionType) {
          if (userSubscriptionType === "PRO" || userSubscriptionType === "PROMAX" || userSubscriptionType === "ENTERPRISE") {
            window.location.href = redirectUrl;
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }, 1000);
    return () => clearInterval(getUserSubscriptionType);
  }, [user]);

  if (loading) {
    return <LoadingFallback />;
  } else {
    return (
      <ChatProvider>
        <ApiErrorBoundaryProvider>
          <App />
        </ApiErrorBoundaryProvider>
      </ChatProvider>
    );
  }
};

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<LimitedUse />);
} else {
  console.error('No root container found');
}
