import React, { useState } from 'react';
import { StopGeneratingIcon } from '~/components';
import { Settings } from 'lucide-react';
import { SetTokenDialog } from './SetTokenDialog';
import store from '~/store';
import { IoStop } from 'react-icons/io5';
import { IoSendSharp } from 'react-icons/io5';

export default function SubmitButton({
  endpoint,
  submitMessage,
  handleStopGenerating,
  disabled,
  isSubmitting,
  isTyping,
  endpointsConfig,
  showRegisterPopup,
}) {
  const [setTokenDialogOpen, setSetTokenDialogOpen] = useState(false);
  const { getToken } = store.useToken(endpoint);

  const isTokenProvided = endpointsConfig?.[endpoint]?.userProvide ? !!getToken() : true;
  const endpointsToHideSetTokens = new Set(['openAI', 'azureOpenAI', 'bingAI']);

  const clickHandler = (e) => {
    e.preventDefault();
    
    if (showRegisterPopup) return; 

    submitMessage();
  };


  const setToken = () => {
    setSetTokenDialogOpen(true);
  };

  if (isSubmitting) {
    return (
      <button
        onClick={handleStopGenerating}
        type="button"
        className=" flex items-center justify-center bg-transparent text-gray-500"
      >
        <div className="m-1 rounded-full p-[9px] text-white bg-[#2E3136]">
          <IoStop className='text-[24px]' />
        </div>
      </button>
    );
  } else if (!isTokenProvided && !endpointsToHideSetTokens.has(endpoint)) {
    return (
      <>
        <button
          onClick={setToken}
          type="button"
          className="text-gray-black group absolute bottom-0 right-0  flex h-[100%] w-auto items-center justify-center bg-transparent pr-1 dark:text-white"
        >
          <div className="flex items-center justify-center rounded-md text-xs group-hover:bg-gray-100 group-disabled:group-hover:bg-transparent dark:group-hover:bg-gray-900 dark:group-disabled:group-hover:bg-transparent">
            <div className="m-0 mr-0 flex items-center justify-center rounded-md p-2 sm:p-2">
              <Settings className="mr-1 inline-block h-auto w-[18px]" />
              Set Token First
            </div>
          </div>
        </button>
        <SetTokenDialog
          open={setTokenDialogOpen}
          onOpenChange={setSetTokenDialogOpen}
          endpoint={endpoint}
        />
      </>
    );
  } else {
    return (
      <button
        onClick={clickHandler}
        disabled={disabled || !isTyping}
        className=" flex items-center justify-center bg-transparent text-gray-500"
        aria-label="Submit Button"
      >
        <div
          className={`m-1 rounded-full p-[10px] focus:border-black text-white bg-black ${isTyping ? 'text-gray-950 dark:text-white' : ''}`}
          role="button"
          aria-label="Submit Button"
        >
          <IoSendSharp size={22} className='pl-[3px]' />
        </div>
      </button>
    );
  }
}