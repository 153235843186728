import { useState, useEffect } from "react";
import { usePricingPopup } from "../helper/store";
import { MdOutlineCancel } from "react-icons/md";
import { showPricing } from "~/components/helper/global";
import { getPrefixLocation } from "~/utils/main";
import LazyDisplay from "./LazyDisplay";
import { cookie } from "~/utils";

export default function RegisterPopup() {
  const pState = usePricingPopup((state) => state);
  const [slug, setSlug] = useState("register-auth");

  useEffect(() => {
    const checkEmail = () => {
      if (cookie.getValue("user_email")) {
        setSlug("pricing");
      } else {
        setSlug("register-auth");
      }
    };

    checkEmail(); 
    const interval = setInterval(checkEmail, 1000); 

    return () => clearInterval(interval); 
  }, [pState.showRegisterPopup]);

  if (!pState.showRegisterPopup) {
    return null;
  }

  const modalStyles = slug === 'register-auth'
  ? 'min-w-[26%] h-[70%] md:min-w-[30%] md:w-full md:max-w-[62%] max-h-720:h-[94%] lg:max-w-[50%] xl:max-w-[36%] xl:h-[70%] min-w-2560:max-w-[28%] min-w-2560:min-w-[26%] min-w-2560:h-[50%] transition-all delay-150 duration-300 ease-in-out'
  : 'transition-all delay-150 duration-300 ease-in-out md:min-w-[710px] md:w-[68%] md:max-w-[68%] max-w-768-1280:h-[90%] h-[85%]';


  return (
    <div 
      className="fixed inset-0 flex items-center justify-center z-[100] bg-black/50" 
      onClick={() => {											
        showPricing(() => {
          pState.setShowRegisterPopup(false);
        });
      }}
    >
      <div 
        className={`w-[90%] rounded-3xl my-auto relative ${modalStyles}`}
      >
        <iframe
          src={`https://${getPrefixLocation()}start.ai-pro.org/${slug}`}
          title="Register Authentication"
          frameBorder="0"
          className="w-full h-full rounded-3xl"
        />
        <LazyDisplay delay={360}>
          <MdOutlineCancel
            className="absolute top-4 right-4 h-5 w-5 text-gray-600 cursor-pointer"
            onClick={() => {
              showPricing(() => {
                pState.setShowRegisterPopup(false);
              });
            }}
          />
        </LazyDisplay>
      </div>
    </div>
  );
}
