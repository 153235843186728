import { forwardRef } from 'react';
import global from '~/utils/global';
import { usePricingPopup } from '../helper/store';
import { BiBadgeCheck } from "react-icons/bi";
import { HiSparkles } from "react-icons/hi2";

const NavDropdown = forwardRef(() => {
  const pState = usePricingPopup((state) => state);

  const handleClickModal = () => {
    global.showPricing(() => {
      pState.setShowRegisterPopup(true);
    });
  };

  return (
    <>
      {window.AIPRO_USER && window.AIPRO_USER?.email === null && (
        <div className="relative rounded-[12px] overflow-hidden bg-black border border-gray-800 max-h-900:hidden">
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: "url('/assets/create-content.png')", opacity: 1 }}
          ></div>
          <div className="relative p-4 flex flex-col space-y-5">
            <div className="space-y-1">
              <h2 className="text-white text-lg font-bold pt-3">Create Your Account</h2>
              <p className="text-[#FFFFFFBF] text-sm">To access more AI apps and features</p>
            </div>
            <div className="space-y-3">
              <div className="flex items-center">
                <BiBadgeCheck className="h-5 w-5 text-white mr-2" />
                <span className="text-white text-sm">10+ AI Models</span>
              </div>
              <div className="flex items-center">
                <BiBadgeCheck className="h-5 w-5 text-white mr-2" />
                <span className="text-white text-sm">10+ AI Apps</span>
              </div>
            </div>
            <button
              onClick={handleClickModal}
              className="bg-gradient-to-r from-[#3E53B8] to-[#2393F1] hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-full flex items-center justify-center"
            >
              <HiSparkles className="h-5 w-5 text-white mr-2" />
              Sign Up Now
            </button>
          </div>
        </div>
      )}

      {window.AIPRO_USER && window.AIPRO_USER?.email === null && (
        <button
          onClick={handleClickModal}
          className="bg-gradient-to-r from-[#3E53B8] to-[#2393F1] hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-full flex items-center justify-center max-h-900:flex hidden"
        >
          <HiSparkles className="h-5 w-5 text-white mr-2" />
          Sign Up Now
        </button>
      )}
    </>
  );
});

export default NavDropdown;
