import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import HeaderDropdown from "./HeaderDropdown";
import { usePricingPopup, subscriptionType } from "~/components/helper/store";
import Settings from "./HeaderSettings";
import { showPricing } from '~/components/helper/global';
import ModelDropdown from '~/components/Header/AiModelDropdown';
import OpenAIIcon from "~/components/icons/OpenAIIcon";
import ClaudeIcon from "~/components/icons/ClaudeIcon";
import DeepSeekIcon from "~/components/icons/DeepSeekIcon";
import GeminiIcon from "~/components/icons/GeminiIcon";
import { HiSparkles } from 'react-icons/hi2';
import global from '~/utils/global';
import { useRecoilValue } from 'recoil';
import store from '~/store';
import { IoDiamond, IoDiamondOutline } from "react-icons/io5";

export default function Header({ showThemeModal, navVisible }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const dropdownRef = useRef(null);
  const modalRef = useRef(null);
  const proState = subscriptionType((state) => state);
  const pState = usePricingPopup((state) => state);
  const messagesTree = useRecoilValue(store.messagesTree);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
      setTimeout(() => setIsDropdownOpen(false), 200);
    }
  };

  const handleModalClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleModalClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleModalClickOutside);
    };
  }, []);

  const handleModelClick = () => {
    global.showPricing(() => {
      pState.setShowRegisterPopup(true);
    });
  };

  return (
    <>
      <header className={`absolute top-0 right-0 bg-white flex items-center justify-between py-2 px-4 z-10 ${navVisible ? 'w-[calc(100vw-320px)] lg:w-[calc(100vw-260px)]' : 'w-[calc(100vw-80px)]'}`}>
        <div className={`${messagesTree?.length ? 'hidden' : 'flex' } items-center relative w-full justify-center flex-wrap`}>
          {!proState.isPro && !proState.isProMax ? (
            !isMobile ? (
              <div className="tabs flex-1 flex flex-col">
                <div className="p-4 flex justify-center">
                  <div className="flex space-x-2">
                    {[
                      { name: "GPT-4o mini", icon: <OpenAIIcon /> },
                      { name: "GPT-4o", icon: <OpenAIIcon />, pro: true },
                      { name: "Claude", icon: <ClaudeIcon />, pro: true },
                      { name: "DeepSeek", icon: <DeepSeekIcon />, pro: true },
                      { name: "Google Gemini", icon: <GeminiIcon />, pro: true },
                    ].map((model) => (
                      <button
                        key={model.name}
                        onClick={() => {
                          if (model.name !== "GPT-4o mini") {
                            showPricing(() => {
                              pState.setShowRegisterPopup(true);
                            });
                          }
                        }}
                        className={`px-3 py-1 rounded-full max-w-1147:text-xs text-sm flex items-center transition-colors duration-200 ${
                          model.name === "GPT-4o mini"
                            ? "bg-[#f5f5f5] text-[#292929] font-semibold"
                            : "hover:bg-[#f5f5f5] hover:text-[#292929]"
                        }`}
                      >
                        {model.icon}
                        <span className="ml-2">{model.name}</span>
                        {model.pro && (
                          <span className="ml-1 text-xs bg-[#E0F3FF] text-[#2872FA] rounded px-1.5">Pro</span>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
                <div className="hidden md:block ml-auto">
                  <ModelDropdown />
                </div>
            )
          ) : null}
          <div className="max-w-1366:hidden xl:absolute xl:right-[0px]">
            <button
              onClick={handleModelClick}
              className="px-5 pr-6 py-2 bg-gradient-to-r from-[#3E53B8] to-[#2393F1] hover:bg-blue-700 text-white rounded-full w-max text-sm font-semibold transition-colors duration-200 self-center mr-10"
            >
              <span className="inline-flex items-center"><HiSparkles className="mr-2" />Go Pro</span>
            </button>
          </div>
          
          <div className="md:hidden items-center hidden max-w-1366:flex ml-auto">
            <button
              onClick={handleModelClick}
              className="p-1 px-3 bg-gradient-to-r from-[#3E53B8] to-[#2393F1] hover:bg-blue-700 text-white rounded-full w-max font-semibold transition-colors duration-200 self-center"
            >
              <span className="inline-flex items-center"><IoDiamondOutline className="mt-2" /></span>
            </button>
          </div>
          
        </div>

        {isDropdownOpen && (
          <div ref={dropdownRef}>
            <HeaderDropdown isVisible={isDropdownVisible} setIsDropdownVisible={setIsDropdownVisible} setShowSettings={setShowSettings} showThemeModal={showThemeModal} />
          </div>
        )}
      </header>



      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: "easeInOut" }}
          >
            <motion.div
              ref={modalRef}
              className="bg-white p-6 rounded-lg shadow-lg w-[400px]"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1, ease: "easeInOut" }}
            >
              <h2 className="text-lg font-bold">Register</h2>
              <p className="mt-2">This is the register popup modal.</p>
              <button
                onClick={() => setIsModalOpen(false)}
                className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Close
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
