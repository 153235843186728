import { useMemo } from 'react';
import { getModelName } from '../helper/modelName.js';

export function useAppModel() {
  const openai_gpt4_model = import.meta.env.VITE_OPENAI_GPT4_MODEL || 'gpt-4-1106-preview';
  const openai_gpt3_model = import.meta.env.VITE_OPENAI_GPT3_MODEL || 'gpt-3.5-turbo-0125';
  const claude_model = import.meta.env.VITE_CLAUDE_MODEL || 'claude-3-5-sonnet-20240620';
  const dalle_model = import.meta.env.VITE_DALLE_MODEL || 'dall-e-3';
  const userPlan = window.AIPRO_USER?.subscription_type || [];
 
  // console.log('User Plan:', userPlan);
  const models = useMemo(() => {

    const modelsList = [
      {
      model: openai_gpt3_model,
      endpoint: 'openAI',
      title: getModelName(openai_gpt3_model),
      desciption: {
        title: getModelName(openai_gpt3_model),
        token: '4,096',
        content: `${getModelName(openai_gpt3_model)} is a fast model from OpenAI, it is great for
                    everday tasks. It offers swift and efficient solutions, making it perfect for
                    various applications.`,
      },
      isDisabled: false,
      isNew: false,
      isBeta: false,
      isDefault: false,
      isVisible: true,
      isBasic: true,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
    },
    {
      model: 'gpt-4o',
      endpoint: 'openAI',
      title: getModelName('gpt-4o'),
      desciption: {
        title: getModelName('gpt-4o'),
        token: '128,000',
        content: `GPT-4o is OpenAI's latest advanced multimodal model that's 2x faster than GPT-4,
                    but with the intelligence to match. Great for long, complex conversations.`,
      },
      isDisabled: false,
      isNew: false,
      isBeta: false,
      isDefault: false,
      isVisible: true,
      isBasic: true,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
    },
    {
      model: 'gpt-4o-mini',
      endpoint: 'openAI',
      title: getModelName('gpt-4o-mini'),
      desciption: {
        title: getModelName('gpt-4o-mini'),
        token: '128,000',
        content: `GPT-4o mini is an optimized AI model developed by OpenAI. It's designed to handle a wide range of tasks with enhanced capabilities in reasoning across various input types. This model is a variation of GPT-4o focused on speed and efficiency, making powerful AI more accessible for various applications.`,
      },
      isDisabled: false,
      isNew: true,
      isBeta: false,
      isDefault: true,
      isVisible: true,
      isBasic: true,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
    },
    {
      model: openai_gpt4_model,
      endpoint: 'openAI',
      title: getModelName(openai_gpt4_model),
      desciption: {
        title: getModelName(openai_gpt4_model),
        token: '8,192',
        content: `With an even larger parameter count, further enhances language capabilities,
                    surpassing its predecessor in comprehensiveness, context understanding, and
                    generating more contextually relevant and coherent responses.`,
      },
      isDisabled: ['PROMAX'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: false,
      isVisible: true,
      isBasic: false,
      isPro: false,
      isPromax: true,
      isEnterprise: false,
    },
    {
      model: 'gemini-1.5-flash',
      endpoint: 'gemini',
      title: getModelName('gemini-1.5-flash'),
      desciption: {
        title: getModelName('gemini-1.5-flash'),
        token: '1,000,000',
        content: 'Gemini 1.5 Flash combines lightning-fast processing with pinpoint accuracy, revolutionizing real-time interactions for chatbots and virtual assistants.',
      },
      isDisabled: ['PRO', 'PROMAX', 'ENTERPRISE'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: true,
      isVisible: true,
      isBasic: false,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
    },
    {
      model: 'gemini-1.5-pro',
      endpoint: 'gemini',
      title: getModelName('gemini-1.5-pro'),
      desciption: {
        title: getModelName('gemini-1.5-pro'),
        token: '1,000,000',
        content: 'Gemini 1.5 Pro is a cutting-edge language model that excels in understanding and generating human-like text with remarkable accuracy. Its advanced capabilities enhance productivity and drive innovation across various industries.',
      },
      isDisabled: ['PRO', 'PROMAX', 'ENTERPRISE'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: false,
      isVisible: true,
      isBasic: false,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
    },
    {
      model: 'meta-llama/Meta-Llama-3-8B-Instruct-Turbo',
      endpoint: 'Llama',
      title: getModelName('meta-llama/Meta-Llama-3-8B-Instruct-Turbo'),
      desciption: {
        title: getModelName('meta-llama/Meta-Llama-3-8B-Instruct-Turbo'),
        token: '8,000',
        content: `Llama-3 8B is the latest in AI innovation from Meta AI. With 8 billion finely-tuned parameters, Llama-3 8B delivers exceptional text generation and understanding while optimizing for speed and efficiency.`,
      },
      isDisabled: ['BASIC','PRO','PROMAX', 'ENTERPRISE'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: true,
      isVisible: true,
      isBasic: true,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
    },

    // {
    //   model: 'meta-llama/Llama-2-70b-chat-hf',
    //   endpoint: 'Llama',
    //   title: getModelName('meta-llama/Llama-2-70b-chat-hf'),
    //   desciption: {
    //     title: getModelName('meta-llama/Llama-2-70b-chat-hf'),
    //     token: '4,096',
    //     content: `Llama-2 70B harnesses the power of 70 billion parameters to deliver unparalleled accuracy and depth in natural language processing and generation.`,
    //   },
    //   isDisabled: ['PROMAX', 'ENTERPRISE'].includes(userPlan) ? false : true,
    //   isNew: false,
    //   isBeta: false,
    //   isDefault: false,
    //   isVisible: true,
    //   isBasic: false,
    //   isPro: false,
    //   isPromax: true,
    //   isEnterprise: true,
    // },
    {
      model: 'meta-llama/Llama-3-70b-chat-hf',
      endpoint: 'Llama',
      title: getModelName('meta-llama/Llama-3-70b-chat-hf'),
      desciption: {
        title: getModelName('meta-llama/Llama-3-70b-chat-hf'),
        token: '8,192',
        content: `Llama-3 (70B), the advanced language model by Meta AI, excels in understanding and generating natural text with remarkable accuracy, offering precise, contextually relevant responses that elevate user experience and streamline operational efficiency.`,
      },
      isDisabled: ['PRO', 'PROMAX', 'ENTERPRISE'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: false,
      isVisible: true,
      isBasic: false,
      isPro: true,
      isPromax: true,
      isEnterprise: true,
    },
    {
      tab: 'claude',
      model: claude_model,
      endpoint: 'claude',
      title: getModelName('claude'),
      desciption: {
        title: getModelName('claude'),
        token: '200,000',
        content:
          'Claude 3.5 Sonnet, the latest innovation by Anthropic, offers unparalleled natural language understanding and generation, ensuring precise, context-aware interactions that elevate your AI-driven experiences.',
      },
      isDisabled: ['PROMAX'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: true,
      isVisible: true,
      isBasic: false,
      isPro: false,
      isPromax: true,
      isEnterprise: false,
    },
    {
      model: dalle_model,
      endpoint: 'dallE',
      title: 'DALL·E',
      desciption: {
        title: 'DALL·E',
        token: undefined,
        content: `DALL·E 3 understands significantly more nuance and detail than our previous systems,
                  allowing you to easily translate your ideas into exceptionally accurate images.`,
      },
      isDisabled: ['PROMAX'].includes(userPlan) ? false : true,
      isNew: false,
      isBeta: false,
      isDefault: true,
      isVisible: true,
      isBasic: false,
      isPro: false,
      isPromax: true,
      isEnterprise: false,
    },
    // {
    //   model: 'google/gemma-7b-it',
    //   endpoint: 'Opensource',
    //   title: getModelName('google/gemma-7b-it'),
    //   desciption: {
    //     title: getModelName('google/gemma-7b-it'),
    //     token: '8,192',
    //     content: `Gemma Instruct LLM is your ultimate guide for precise and actionable instructions, designed to simplify complex tasks and empower you with expert knowledge effortlessly.`,
    //   },
    //   isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
    //   isNew: false,
    //   isBeta: true,
    //   isDefault: true,
    //   isVisible: true,
    //   isBasic: false,
    //   isPro: false,
    //   isPromax: true,
    //   isEnterprise: true,
    // },
    // {
    //   model: 'Qwen/Qwen1.5-72B-Chat',
    //   endpoint: 'Opensource',
    //   title: getModelName('Qwen/Qwen1.5-72B-Chat'),
    //   desciption: {
    //     title: getModelName('Qwen/Qwen1.5-72B-Chat'),
    //     token: '128,000',
    //     content: `Qwen 1.5 Chat (72B) transforms your interactions with its state-of-the-art 72 billion parameter AI, delivering engaging, contextually accurate, and fluid conversations.`,
    //   },
    //   isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
    //   isNew: false,
    //   isBeta: true,
    //   isDefault: false,
    //   isVisible: true,
    //   isBasic: false,
    //   isPro: false,
    //   isPromax: true,
    //   isEnterprise: true,
    // },
    // {
    //   model: 'mistralai/Mixtral-8x22B-Instruct-v0.1',
    //   endpoint: 'Opensource',
    //   title: getModelName('mistralai/Mixtral-8x22B-Instruct-v0.1'),
    //   desciption: {
    //     title: getModelName('mistralai/Mixtral-8x22B-Instruct-v0.1'),
    //     token: '65,400',
    //     content: `Mixtral-8x22B Instruct (141B) is your ultimate ally for precision and expertise, delivering detailed, context-aware guidance with its massive 141 billion parameter architecture.`,
    //   },
    //   isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
    //   isNew: false,
    //   isBeta: true,
    //   isDefault: false,
    //   isVisible: true,
    //   isBasic: false,
    //   isPro: false,
    //   isPromax: true,
    //   isEnterprise: true,
    // },
    // {
    //   model: 'openchat/openchat-3.5-1210',
    //   endpoint: 'Opensource',
    //   title: getModelName('openchat/openchat-3.5-1210'),
    //   desciption: {
    //     title: getModelName('openchat/openchat-3.5-1210'),
    //     token: '8,192',
    //     content: `Enhance your customer support, virtual assistance, and educational experiences with OpenChat 3.5's exceptional conversational capabilities with its 7 billion parameter AI, delivering natural, engaging, and intuitive conversations.`,
    //   },
    //   isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
    //   isNew: false,
    //   isBeta: true,
    //   isDefault: false,
    //   isVisible: true,
    //   isBasic: false,
    //   isPro: false,
    //   isPromax: true,
    //   isEnterprise: true,
    // },
    // {
    //   model: 'teknium/OpenHermes-2-Mistral-7B',
    //   endpoint: 'Opensource',
    //   title: getModelName('teknium/OpenHermes-2-Mistral-7B'),
    //   desciption: {
    //     title: getModelName('teknium/OpenHermes-2-Mistral-7B'),
    //     token: '8,192',
    //     content: `Whether you need sophisticated chatbots, impeccable content creation, or enhanced language comprehension, OpenHermes-2-Mistral (7B) ensures unparalleled performance and versatility.`,
    //   },
    //   isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
    //   isNew: false,
    //   isBeta: true,
    //   isDefault: false,
    //   isVisible: true,
    //   isBasic: false,
    //   isPro: false,
    //   isPromax: true,
    //   isEnterprise: true,
    // },
    // {
    //   model: 'Open-Orca/Mistral-7B-OpenOrca',
    //   endpoint: 'Opensource',
    //   title: getModelName('Open-Orca/Mistral-7B-OpenOrca'),
    //   desciption: {
    //     title: getModelName('Open-Orca/Mistral-7B-OpenOrca'),
    //     token: '8,192',
    //     content: `OpenOrca Mistral (7B) 8K harnesses the power of a 7 billion parameter AI to deliver unparalleled text processing and generation capabilities.`,
    //   },
    //   isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
    //   isNew: false,
    //   isBeta: true,
    //   isDefault: false,
    //   isVisible: true,
    //   isBasic: false,
    //   isPro: false,
    //   isPromax: true,
    //   isEnterprise: true,
    // },
    // {
    //   model: 'lmsys/vicuna-13b-v1.5',
    //   endpoint: 'Opensource',
    //   title: getModelName('lmsys/vicuna-13b-v1.5'),
    //   desciption: {
    //     title: getModelName('lmsys/vicuna-13b-v1.5'),
    //     token: '16,384',
    //     content: `From crafting compelling content to powering intelligent virtual assistants, Vicuna v1.5 sets a new standard in sophisticated AI capabilities, ensuring seamless and impactful interactions across diverse applications.`,
    //   },
    //   isDisabled: ['PROMAX','ENTERPRISE'].includes(userPlan) ? false : true,
    //   isNew: false,
    //   isBeta: true,
    //   isDefault: false,
    //   isVisible: true,
    //   isBasic: false,
    //   isPro: false,
    //   isPromax: true,
    //   isEnterprise: true,
    // },
  ];

    // Set isVisible based on userPlan conditions
    modelsList.forEach((model) => {
      // console.log("mod:",model);
      if (userPlan.includes('ENTERPRISE') && !model.isEnterprise) {
        model.isVisible = false;
      }
    });

    return modelsList.filter((model) => model.isVisible);
}, [userPlan]);

return models;
}