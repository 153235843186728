import React from 'react';
import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { FaChevronDown } from 'react-icons/fa';
import SubmitButton from './SubmitButton';
import { BsPaperclip } from "react-icons/bs";
import ModelDropdown from '../Header/AiModelDropdown';
import { useRecoilState } from 'recoil';
import store from '~/store';
import FileUpload from './FileUpload';
import {
	usePopup,
	subscriptionType,
	usePricingPopup,
	useCustomData,
} from '~/components/helper/store';
import { aiModelState } from "~/store/aiModels";
import Microphone from './Microphone';
import global from '~/utils/global';

const ChatInput = ({ text, setText, inputRef, isSubmitting, placeholder, onSubmit, submitMessage, handleStopGenerating, disabled, isTyping, endpointsConfig, endpoint, isNotAppendable }) => {
	const isComposing = React.useRef(false);
	const [selectedModel, setSelectedModel] = useRecoilState(aiModelState);
	const { setShowRegisterPopup, showRegisterPopup } = usePricingPopup();

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' && isSubmitting) return;

		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			if (!isComposing.current) {
				onSubmit();
			}
		}
	};

	const handleKeyUp = (e) => {
		if (e.keyCode === 8 && e.target.value.trim() === '') {
			setText(e.target.value);
		}

		if (e.key === 'Enter' && e.shiftKey) {
			return console.log('Enter + Shift');
		}

		if (isSubmitting) return;
	};

	const handleCompositionStart = () => {
		isComposing.current = true;
	};

	const handleCompositionEnd = () => {
		isComposing.current = false;
	};

	const changeHandler = (e) => {
		const { value } = e.target;
		setText(value);
	};
	const [conversation, setConversation] = useRecoilState(store.conversation);
	const { hasFile, imagesPreview, imageUrls, setImagesPreview, setImageUrls } = useCustomData(
		(state) => state,
	);

	const showFileUpload = conversation && conversation.endpoint === 'openAI' && conversation.model === 'gpt-4o';


	const handleClickModal = () => {
		global.showPricing(() => {
			setShowRegisterPopup(true);
		});
	};

	return (
		<div className="flex flex-col gap-3 p-4 pt-6 border border-[#E4E4E780] rounded-3xl bg-[#F9F9F9]">
			<div className="flex items-center gap-2">
				<TextareaAutosize
					id="text-input-odfigj"
					data-testid="text-input"
					tabIndex="1"
					autoFocus={1}
					// style={{maxHeight: '200px', height: '24px', overflowY: 'hidden'}}
					rows="3"
					value={disabled || isNotAppendable || showRegisterPopup ? '' : text} // Disable input when modal is open
					ref={inputRef}
					onChange={changeHandler}
					onKeyDown={handleKeyDown}
					onKeyUp={handleKeyUp}
					onCompositionStart={handleCompositionStart}
					onCompositionEnd={handleCompositionEnd}
					placeholder={placeholder}
					disabled={disabled || isNotAppendable}
					aria-label="Text input"
					className="m-0 flex h-auto max-h-[5.53rem] flex-1 resize-none overflow-auto border-0 bg-transparent p-0 leading-6 focus:ring-0 focus-visible:ring-0 dark:bg-transparent placeholder:text-[#00000059] md:ml-2 outline-none bg-[#F9F9F9]"
				/>
			</div>
			<div className="flex items-center justify-between gap-4">
				<div className="flex gap-4 items-center">
					{(selectedModel === "gpt-4o" || selectedModel === "gpt-4o-mini") && (
						showFileUpload ? (
							<FileUpload
								onFileChange={(value) => {
									setImagesPreview([...imagesPreview, ...value]);
								}}
								onUpload={(value) => {
									setImageUrls([...imageUrls, ...value]);
								}}
							/>
						) : (
							<div className='flex items-center justify-center' onClick={handleClickModal}>
								<label
									htmlFor="Attachment-unavailable"
									className="relative p-2.5 rounded-full border-[#E4E4E7] border bg-[#FFFFFF] hover:bg-[#F5F5F5]"
									role="button"
									aria-label="Attachment Unavailable"
									title="Attachment Unavailable"
								>
									<BsPaperclip size={22} className='text-[#1A1D21BF]' />
								</label>
							</div>
						)
					)}
					<div className="hidden md:block">
						<ModelDropdown />
					</div>
				</div>
				<div className='inline-flex'>
					<Microphone />
					<SubmitButton
						submitMessage={submitMessage}
						handleStopGenerating={handleStopGenerating}
						disabled={disabled || showRegisterPopup }
						isSubmitting={isSubmitting}
						isTyping={isTyping}
						endpointsConfig={endpointsConfig}
						endpoint={endpoint}
						showRegisterPopup={showRegisterPopup}
					/>
				</div>
			</div>
		</div>
	);
};

export default ChatInput;
