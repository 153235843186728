import { useState } from 'react';
import { File, Image } from 'lucide-react';
import { Menu, Transition } from '@headlessui/react';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import { cookie, redirectApps, prefix } from '~/utils/';

import NavDropdown from './NavDropdown';
import NavLink from './NavLink';
import { subscriptionType, usePricingPopup } from '~/components/helper/store';
import { showPricing } from '~/components/helper/global';

const AdvanceTools = () => {
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const sState = usePricingPopup((state) => state);
  const proState = subscriptionType((state) => state);

  const myAccountLink = () => {
    if (cookie.getValue('rdct') && cookie.getValue('rdct') in redirectApps) {
      return `https://${prefix}ai-pro.org/pricing-redirect?from=chat`;
    }
    return `https://${prefix}start.ai-pro.org/my-account`;
  };

  const handlePricing = () => {
    if (!proState.isPro || !proState.isProMax) {
      
      if ((cookie.getValue('flow') === null || cookie.getValue('flow') === undefined || cookie.getValue('flow') === "") && (cookie.getValue('kt8typtb') != "arcana_wp")) {
        showPricing(() => {
          sState.setShowPricingPopup(true);
        });
      } else {
        window.location.href = `https://${prefix}ai-pro.org/pricing-redirect?from=chatapp`;
      }

    }
  };

  return (
    <>
      <Menu>
        <NavDropdown/>
      </Menu>
    </>
  );
};

export default AdvanceTools;
