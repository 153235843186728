// import { Plugin, GPTIcon, AnthropicIcon, OpensourceIcon2, GeminiIcon } from '~/components/svg';
// import { useAuthContext } from '~/hooks';
import { cn } from '~/utils';
import { FaUser } from 'react-icons/fa6';
const getIcon = (props) => {
  const { size = 30, isCreatedByUser, button, model, message = true } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const { user } = useAuthContext();

  if (isCreatedByUser) {
    return (
      <div
        title="User icon"
        style={{
          width: size,
          height: size,
        }}
        className={
          'bg-[#E0F3FF] relative flex items-center justify-center rounded-full text-text-[#2872FA] ' + props?.className
        }
        role="img"
      >
        <svg
          stroke="currentColor"
          fill="#2872FA"
          strokeWidth="0"
          viewBox="0 0 448 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="User icon"
        >
          <path
            role="img"
            aria-label="User icon"
            d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
          ></path>
        </svg>
      </div>
    );
  } else if (!isCreatedByUser) {
    // const { endpoint, error } = props;
    const { error } = props;

    // let icon, bg, name;
    // if (endpoint === 'azureOpenAI') {
    //   const { chatGptLabel } = props;
    //   icon = <GPTIcon size={size * 0.7} />;
    //   bg = 'linear-gradient(0.375turn, #61bde2, #4389d0)';
    //   name = chatGptLabel || 'ChatGPT';
    // } else if (endpoint === 'openAI' || (endpoint === 'gptPlugins' && message)) {
    //   const { chatGptLabel } = props;
    //   icon = <GPTIcon size={size * 0.7} />;

    //   if (model && model == 'gpt-4o') {
    //     bg = '#fff';
    //     icon = <GPTIcon size={size * 0.7} color="#000" />;
    //   } else if (model && model.toLowerCase().startsWith('gpt-4')) {
    //     bg = '#AB68FF';
    //   } else if (chatGptLabel) {
    //     bg = `rgba(16, 163, 127, ${button ? 0.75 : 1})`;
    //   } else {
    //     bg = `rgba(16, 163, 127, ${button ? 0.75 : 1})`;
    //   }

    //   name = chatGptLabel || 'ChatGPT';
    // } else if (endpoint === 'gptPlugins' && !message) {
    //   icon = <Plugin size={size * 0.7} />;
    //   bg = `rgba(69, 89, 164, ${button ? 0.75 : 1})`;
    //   name = 'Plugins';
    // } else if (endpoint === 'google') {
    //   const { modelLabel } = props;
    //   icon = <img src="/assets/google-palm.svg" alt="Palm Icon" />;
    //   name = modelLabel || 'PaLM2';
    // } else if (endpoint === 'Opensource') {
    //   const { modelLabel } = props;
    //   icon = <OpensourceIcon2 size={size} />;
    //   name = modelLabel || 'Opensource';
    // } else if (endpoint === 'anthropic') {
    //   const { modelLabel } = props;
    //   icon = <AnthropicIcon size={size * 0.7} />;
    //   bg = '#d09a74';
    //   name = modelLabel || 'Claude';
    // } else if (endpoint === 'bingAI') {
    //   const { jailbreak } = props;
    //   if (jailbreak) {
    //     icon = <img src="/assets/bingai-jb.png" alt="Bing Icon" />;
    //     name = 'Sydney';
    //   } else {
    //     icon = <img src="/assets/bingai.png" alt="Sydney Icon" />;
    //     name = 'BingAI';
    //   }
    // } else if (endpoint === 'chatGPTBrowser') {
    //   icon = <GPTIcon size={size * 0.7} />;
    //   bg =
    //     model && model.toLowerCase().startsWith('gpt-4')
    //       ? '#AB68FF'
    //       : `rgba(0, 163, 255, ${button ? 0.75 : 1})`;
    //   name = 'ChatGPT';
    // } else if(endpoint === 'gemini') {
    //   icon = <GeminiIcon size={size * 0.7} />;
    //   name = 'Gemini';
    // } else if (endpoint === null) {
    //   icon = <GPTIcon size={size * 0.7} />;
    //   bg = 'grey';
    //   name = 'N/A';
    // } else {
    //   icon = <GPTIcon size={size * 0.7} />;
    //   bg = 'grey';
    //   name = 'UNKNOWN';
    // }

    return (
      <div
        title={name}
        style={{
          width: size,
          height: size,
        }}
        className={cn(
          'relative flex items-center justify-center rounded-full text-white ',
          props?.className ?? '',
        )}
        role="button"
        aria-label="New Topic"
      >
        {/* {icon} */}
        <img
          src="/assets/ai-pro-logo-new.png"
          alt="Ai Pro"
          width="841"
          height="512"
          className="h-auto w-[100%] block dark:hidden"
        />
        <img
          src="/assets/ai-pro-logo-new.png"
          alt="Ai Pro"
          width="841"
          height="512"
          className="h-auto w-[100%] hidden dark:block invert"
        />
        {error && (
          <span className="absolute right-0 top-[20px] -mr-2 flex h-4 w-4 items-center justify-center rounded-full border border-white bg-red-500 text-[10px] text-white">
            !
          </span>
        )}
      </div>
    );
  }
};

export default getIcon;
