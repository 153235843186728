import React, { useEffect, useState } from "react";

const LazyDisplay = ({ delay = 0, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return isVisible ? <>{children}</> : null;
}

export default LazyDisplay