import React, { useState, useEffect, useRef } from "react";
import { BiCode, BiExpandAlt, BiPencil } from "react-icons/bi";
import { MdOutlineSummarize } from "react-icons/md";
import { HiOutlineSparkles } from "react-icons/hi2";
import { RiImageEditLine } from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import { CiCircleMore } from "react-icons/ci";

const ActionButtons = ({ isDark, setText }) => {
  const [selectedAction, setSelectedAction] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const containerRef = useRef(null);

  const handleClick = (text) => {
    setText(text);
  };

  const baseSuggestions = {
    "Generate Image": [
      "Generate Image of a Landscape",
      "Generate Image of a Futuristic City",
      "Generate Image of Birds in Flight",
    ],
    Summarize: [
      "Summarize this article",
      "Summarize this concept",
      "Summarize in bullet points",
    ],
    "Write Code": [
      "Write Code for a React component",
      "Write Code for a Python script",
      "Write Code for a REST API",
    ],
    "Improve Writing": [
      "Improve Writing to be more engaging",
      "Improve Writing by fixing grammar",
      "Improve Writing for clarity",
    ],
    "Analyze Data": [
      "Analyze Data trends in this dataset",
      "Analyze Data for correlations",
      "Analyze Data using statistical methods",
    ],
    "Expand Ideas": [
      "Expand Ideas with more details",
      "Expand Ideas with examples",
      "Expand Ideas on key points",
    ],
    "Spark Creativity": [
      "Spark Creativity with a writing prompt",
      "Spark Creativity with a project idea",
      "Spark Creativity with techniques",
    ],

  };

  const icons = [
    <RiImageEditLine size={15} className="text-[#6C71FF]" />,
    <MdOutlineSummarize size={15} className="text-[#EA8444]" />,
    <BiCode size={15} className="text-[#42E2AF]" />,
    <BiPencil size={15} className="text-[#D19AD6]" />,
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4968 3.5C10.4968 3.36739 10.5495 3.24022 10.6433 3.14645C10.7371 3.05268 10.8642 3 10.9968 3H14.9968C15.1294 3 15.2566 3.05268 15.3504 3.14645C15.4442 3.24022 15.4968 3.36739 15.4968 3.5V7.5C15.4968 7.63261 15.4442 7.75979 15.3504 7.85355C15.2566 7.94732 15.1294 8 14.9968 8C14.8642 8 14.7371 7.94732 14.6433 7.85355C14.5495 7.75979 14.4968 7.63261 14.4968 7.5V4.9L10.8838 9.317C10.8396 9.37099 10.7846 9.41511 10.7223 9.44655C10.6599 9.47798 10.5917 9.49602 10.522 9.4995C10.4523 9.50299 10.3827 9.49184 10.3175 9.46678C10.2524 9.44172 10.1932 9.4033 10.1438 9.354L7.55684 6.767L3.90084 11.794C3.82086 11.8957 3.70458 11.9624 3.57642 11.9802C3.44827 11.9979 3.31822 11.9653 3.21361 11.8892C3.109 11.8131 3.03797 11.6994 3.01545 11.572C2.99293 11.4446 3.02067 11.3134 3.09284 11.206L7.09284 5.706C7.13529 5.64751 7.18993 5.59893 7.25297 5.56359C7.31601 5.52825 7.38596 5.50699 7.45801 5.50129C7.53005 5.49559 7.60248 5.50557 7.6703 5.53055C7.73811 5.55554 7.79971 5.59492 7.85084 5.646L10.4598 8.256L13.9418 4H10.9968C10.8642 4 10.7371 3.94732 10.6433 3.85355C10.5495 3.75978 10.4968 3.63261 10.4968 3.5Z" fill="#42E2AF" />
    </svg>,
    <BiExpandAlt size={15} className="text-[#6C71FF]" />,
    <HiOutlineSparkles size={15} className="text-[#E2C542]" />,
  ];

  const buttons = Object.keys(baseSuggestions).map((key, index) => ({
    icon: icons[index],
    text: key,
  }));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setSelectedAction(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      ref={containerRef}
      className="flex flex-col items-center w-full mt-4 gap-4 max-w-[800px] mx-auto h-[100px]"
    >
      <AnimatePresence>
        {selectedAction && baseSuggestions[selectedAction] ? (
          <motion.div
            className="flex flex-col items-center w-full gap-2 p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {baseSuggestions[selectedAction].map((suggestion, index) => (
              <div key={index} className="w-full hover:rounded-md border-b hover:border-b-0 h-[40px]">
                <SuggestionButton
                  text={suggestion}
                  isDark={isDark}
                  onClick={() => handleClick(suggestion)}
                />
              </div>
            ))}
          </motion.div>
        ) : (
          <>
            <div className="flex flex-wrap justify-center w-full gap-4">
              {buttons.slice(0, showMore ? 4 : 3).map((btn, index) => (
                <ActionButton
                  key={index}
                  icon={btn.icon}
                  text={btn.text}
                  isDark={isDark}
                  onClick={() => setSelectedAction(btn.text)}
                />
              ))}
              {!showMore && (
                <button
                  className={`px-4 py-2 rounded-[10px] border text-sm transition-colors ${isDark
                      ? "border-gray-600 text-gray-500 hover:bg-gray-100"
                      : "border-gray-300 text-gray-600 hover:bg-gray-100"
                    }`}
                  onClick={() => setShowMore(true)}
                >
                  <div className="inline-flex items-center gap-2"><CiCircleMore />See More...</div>
                </button>
              )}
            </div>
            <AnimatePresence>
              {showMore && (
                <motion.div
                  className="flex flex-wrap justify-center w-full gap-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {buttons.slice(4).map((btn, index) => (
                    <ActionButton
                      key={index + 3}
                      icon={btn.icon}
                      text={btn.text}
                      isDark={isDark}
                      onClick={() => setSelectedAction(btn.text)}
                    />
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

const ActionButton = ({ icon, text, isDark, onClick }) => (
  <button
    className={`flex items-center gap-2 px-4 py-2 rounded-[10px] border text-sm transition-colors ${isDark
        ? "border-gray-600 text-gray-500 md:hover:bg-gray-100"
        : "border-gray-300 text-gray-600 md:hover:bg-gray-100"
      }`}
    onClick={onClick}
  >
    {icon}
    {text}
  </button>
);

const SuggestionButton = ({ text, isDark, onClick }) => {
  const [firstPart, ...rest] = text.split(" ");
  return (
    <button
      className="w-full px-4 py-2 text-left rounded-xl transition-colors font-medium hover:bg-gray-100"
      onClick={onClick}
    >
      <span className="text-gray-500">{firstPart}</span>{" "}
      <span className="text-black">{rest.join(" ")}</span>
    </button>
  );
};

export default ActionButtons;
