import React from 'react';
import { useRecoilValue } from 'recoil';
import store from '~/store';

import global from '~/utils/global';
import { usePricingPopup } from '../helper/store';

export default function Footer({navVisible}) {
  const { setShowRegisterPopup } = usePricingPopup();
  const messagesTree = useRecoilValue(store.messagesTree);

  const handleClickModal = () => {
    global.showPricing(() => {
      setShowRegisterPopup(true);
    });
  };

  return (
    <>
      {!(messagesTree?.length ?? 0) && (
        <div className={`hidden absolute bottom-5 right-0 md:flex items-center justify-center py-2 px-4 z-10 ${navVisible ? 'w-[calc(100vw-252px)]' : 'w-[calc(100vw-17px)]'} text-gray-500 space-x-6 text-xs`}>
          <a href="https://ai-pro.org/about-us/" className="hover:underline">
            About Us
          </a>
          <a href="https://ai-pro.org/privacy-policy/" className="hover:underline">
            Privacy Policy
          </a>
          <button onClick={handleClickModal} className="hover:underline">
          {window.AIPRO_USER && window.AIPRO_USER?.email === null ? (
            'Register'
          ) : (
            'Pricing'
          )}
          </button>
        </div>
      )}
    </>
  );
}
