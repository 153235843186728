import React, { useState, useEffect } from 'react';
import store from '~/store';
import { useChatContext } from '~/hooks/ChatContext';
import { useRecoilValue } from 'recoil';
import { showPricing } from '../helper/global';
import { usePricingPopup } from '../helper/store';
import { IoIosDocument } from "react-icons/io";

const NewChat = ({ onAfterClick }) => {
  const { newConversation } = store.useConversation();
  const { resetPoweredBy } = useChatContext();
  const messagesTree = useRecoilValue(store.messagesTree);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const pState = usePricingPopup((state) => state);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const clickHandler = () => {
    newConversation();
    if (messagesTree?.length > 0) {
      resetPoweredBy();
    }
    if (isMobile) {
      setTimeout(onAfterClick, 200);
    }
  };

  const popUpHandler = () => {
    showPricing(() => {
      pState.setShowRegisterPopup(true);
    });
  };

  return (
    <>
      <div className="hidden md:flex items-center py-6 pt-5">
        <img src="/assets/ai-pro-logo-new.png" alt="Chatbot Pro" className="w-10 h-10 mb-2" />
        <span className="ml-2 text-base font-semibold text-gray-900">Chatbot Pro</span>
      </div>
      <div className='inline-flex gap-2 pb-2'>
        <SVGIcon type="chatbotTools" width={17} height={16} />
        <span className='font-bold text-xs'>Chatbot Tools</span>
      </div>
      <ChatButton onClick={clickHandler} iconType="newChat" label="New Chat" />
      <ChatButton onClick={popUpHandler} iconType="imageGeneration" label="Image Generation" />
      <ChatButton onClick={popUpHandler} iconType="chatpdf" label="AI PDF Reader" />
    </>
  );
};

const ChatButton = ({ onClick, iconType, label }) => (
  <div
    onClick={onClick}
    className="new-chat-btn text-sm transition-all rounded-lg p-1 mb-1 duration-200 hover:bg-gray-100 dark:hover:bg-gray-800"
    role="button"
    tabIndex={0}
    onKeyDown={(e) => e.key === 'Enter' && onClick()}
    aria-label={label}
  >
    <span className='inline-flex pt-1 items-center'>
      <div className='mr-2'>
        <SVGIcon type={iconType} />
      </div>
      {label}
    </span>
  </div>
);

const SVGIcon = ({ type, width = 27, height = 26, fillColor = "#2872FA" }) => {
  const icons = {
    newChat: (
      <svg width={width} height={height} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="26" height="26" rx="13" fill="white" />
        <rect x="1" y="0.5" width="25" height="25" rx="12.5" stroke="black" strokeOpacity="0.1" />
        <path d="M9.5 15.1984V17H11.3L16.2786 12.0232L14.4795 10.2225L9.5 15.1984ZM17.1268 11.1742L15.3277 9.37172L16.6991 8L18.5 9.80162L17.1268 11.1742Z" fill={fillColor} />
      </svg>
    ),
    chatbotTools: (
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 2L7.22533 5.87533C7.1601 6.07367 7.0492 6.25392 6.90156 6.40156C6.75392 6.5492 6.57367 6.6601 6.37533 6.72533L2.5 8L6.37533 9.27467C6.57367 9.3399 6.75392 9.4508 6.90156 9.59844C7.0492 9.74608 7.1601 9.92633 7.22533 10.1247L8.5 14L9.77467 10.1247C9.8399 9.92633 9.9508 9.74608 10.0984 9.59844C10.2461 9.4508 10.4263 9.3399 10.6247 9.27467L14.5 8L10.6247 6.72533C10.4263 6.6601 10.2461 6.5492 10.0984 6.40156C9.9508 6.25392 9.8399 6.07367 9.77467 5.87533L8.5 2Z" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.83594 2V4.66667" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13.1641 11.3335V14.0002" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M2.5 3.3335H5.16667" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.8359 12.6665H14.5026" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
      </svg>

    ),
    imageGeneration: (
      <svg width={width} height={height} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="0.5" width="25" height="25" rx="12.5" fill="white" />
        <rect x="1" y="0.5" width="25" height="25" rx="12.5" stroke="#E6E6E6" />
        <path d="M9.95767 12.5666L5.70312 16.8999H21.3031L15.6304 10.3999L11.3759 14.7332L9.95767 12.5666Z" fill="#9747FF" />
        <ellipse cx="12.1984" cy="10.4001" rx="1.3" ry="1.3" fill="#FFD11B" />
      </svg>
    ),
    chatpdf: (
      <div className='p-1 bg-white border-[#E6E6E6] border rounded-full'>
        <IoIosDocument className='text-[#9747FF] w-[17px] h-[16px]' />
      </div>
    ),
  };
  return icons[type] || null;
};

export default NewChat;